import { useEffect } from 'react';
import Events from '@activebrands/core-web/libs/Events';
import { AccountEvents } from '@activebrands/core-web/libs/Events/types';

declare global {
    interface Window {
        d365mktforms: Record<string, any>;
    }
}

interface NewsletterWidgetProps {
    _uid: string;
    formApiBaseUrl: string;
    formId: string;
    formUrl: string;
    id?: string;
}

/**
 * NewsletterWidget is a component that prints a div and creates a form from d365 inside.
 *
 * @param {LipScoreProductWidgetProps} props - The component props.
 * @returns {JSX.Element} The rendered component.
 *
 * @see {@link https://learn.microsoft.com/en-us/dynamics365/customer-insights/journeys/developer/realtime-marketing-form-client-side-extensibility}
 */
const NewsletterWidget = ({ _uid, formApiBaseUrl, formId, formUrl, id, ...rest }: NewsletterWidgetProps) => {
    if (!_uid || !formApiBaseUrl || !formId || !formUrl) {
        return null;
    }

    // The div id must be unique for each form
    const divId = `${formId}${_uid}`;

    const handleSubmit = () => {
        Events.trigger(AccountEvents.EMAIL_SIGNUP, {});
    };

    useEffect(() => {
        const divElement = document.getElementById(divId);
        let formElement: HTMLFormElement | null = null;

        if (window.d365mktforms && divElement) {
            divElement.appendChild(window.d365mktforms.createForm(formId, formApiBaseUrl, formUrl));

            // Wait to ensure it's in the DOM
            setTimeout(() => {
                formElement = divElement.querySelector('form');
                if (formElement) {
                    formElement.addEventListener('submit', handleSubmit);
                }
            }, 0);
        }

        return () => {
            if (formElement) {
                formElement.removeEventListener('submit', handleSubmit);
            }
        };
    }, []);

    return (
        <div id={id} {...rest}>
            <div id={divId} />
        </div>
    );
};

export default NewsletterWidget;
